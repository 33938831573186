/* eslint-disable operator-linebreak */
import axios from 'axios';
import urlFriendlyConvertor from '../helper/urlFriendlyConvertor';
import i18n from 'i18next';

const axiosClient = axios.create();
axiosClient.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axiosClient.defaults.timeout = process.env.REACT_APP_TIMEOUT;
axiosClient.defaults.withCredentials = true;

axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

axiosClient.interceptors.request.use(
  (config) => {
    config.headers['Accept-Language'] = i18n.language || 'en';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // We don't do anything with them for now
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      window.location.href = `${process.env.REACT_APP_FIELDPIE_OLD_URL}/logout/`;
    }

    return Promise.reject(error);
  }
);

export function getRequest(path, config, removeBlankStringFields) {
  if (config && config.params) {
    const params = { ...config.params };
    config.params = urlFriendlyConvertor(params, removeBlankStringFields);
  }

  return axiosClient.get(`/${path}`, config).then((response) => response);
}

export function postRequest(path, payload, config) {
  return axiosClient.post(`/${path}`, payload, config).then((response) => response);
}

export function patchRequest(path, payload, config) {
  return axiosClient.patch(`/${path}`, payload, config).then((response) => response);
}

export function putRequest(path, payload, config) {
  return axiosClient.put(`/${path}`, payload, config).then((response) => response);
}

export function deleteRequest(path, config) {
  return axiosClient.delete(`/${path}`, config).then((response) => response);
}
