import { putRequest, postRequest } from '../httpclient/httpClient';

const UPDATE_MENU_CLOSED = 'user/update_menu_closed';
const GET_SAVED_COLUMNS = 'user/get_saved_columns';
const SAVE_COLUMNS = 'user/save_columns';

export async function updateMenuClosed(menuClosed) {
  return await putRequest(UPDATE_MENU_CLOSED, menuClosed);
}

export async function getSavedColumns(pageName) {
  return await postRequest(GET_SAVED_COLUMNS, pageName);
}

export async function saveColumns(savedColumns) {
  return await postRequest(SAVE_COLUMNS, savedColumns);
}
