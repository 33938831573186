import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function Notification({ message, status, open, handleClose, handleExited }) {
  const closeAction = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <Alert variant="filled" severity={status} sx={{ width: '100%' }} action={closeAction}>
        {message}
      </Alert>
    </Snackbar>
  );
}

Notification.propTypes = {
  message: PropTypes.string,
  status: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleExited: PropTypes.func
};

export const NotificationContext = React.createContext();

// eslint-disable-next-line react/prop-types
export function NotificationProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [messageData, setMessageData] = useState(undefined);
  const queueRef = useRef([]);

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  function processQueue() {
    if (queueRef.current.length > 0) {
      setMessageData(queueRef.current.shift());
      setOpen(true);
    }
  }

  function handleExited() {
    processQueue();
  }

  function createNotification(notification) {
    queueRef.current.push(notification);

    if (open) {
      setOpen(false);
    } else {
      processQueue();
    }
  }

  return (
    <NotificationContext.Provider value={{ createNotification }}>
      {children}
      <Notification {...messageData} open={open} handleClose={handleClose} handleExited={handleExited} />
    </NotificationContext.Provider>
  );
}
