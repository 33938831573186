import React, { useEffect } from 'react';
import i18n from 'i18next';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import AppMenu from './menu/AppMenu';
import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';
import * as authService from './auth/authService';
import { useResourcesContext } from './resource/ResourceContext';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import router from './routes';
import theme from './theme';

// TODO genel bir logging mekanizması, console.log, console.error yerine, hataları örneğin datadog'da görebileceğimiz bir yapı?

function App() {
  const { setLoggedInUserInfo } = useResourcesContext();

  useEffect(() => {
    async function getInitialCsrfToken() {
      try {
        await authService.csrf();
      } catch (error) {
        console.log(error);
      }
    }

    async function getLoggedInUserInfo() {
      try {
        const userInfo = await authService.getLoggedInUserInfo();
        setLoggedInUserInfo(userInfo.data);

        if (userInfo.data.language) {
          const language = userInfo.data.language.split('_')[0];
          i18n.changeLanguage(language);
          document.documentElement.lang = language;
          localStorage.setItem('language', language);
        }

        if (userInfo.data.menuClosed) {
          localStorage.setItem('menuClosed', userInfo.data.menuClosed);
        }

        if (!userInfo.data.id && !window.location.href.endsWith('/login') && !window.location.href.includes('/v1bridge')) {
          window.location.href = `${process.env.REACT_APP_FIELDPIE_OLD_URL}/logout/`;
        }

        if (window.location.href.endsWith('/logout')) {
          await authService.logout();
        }
      } catch (error) {
        console.log(error);
      }
    }

    getInitialCsrfToken();
    getLoggedInUserInfo();
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={(details) => {
        // TODO Reset the state of your app so the error doesn't happen again
      }}
    >
      <iframe id="v1KeepAliveIFrame" src={`${process.env.REACT_APP_FIELDPIE_OLD_URL}/keepalive/`} width="0px" height="0px" style={{ display: 'none' }} />
      <ThemeProvider theme={theme}>
        <AppMenu>
          <RouterProvider router={router} />
        </AppMenu>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
