import { postRequest } from '../httpclient/httpClient';

const BLOB_STORAGE_URL = process.env.REACT_APP_BLOB_STORAGE_URL;
const UPLOAD_FILE_PATH = 'storage/upload';

export const containers = {
  userPhoto: 'userphoto'
};

export function buildPath(container, path) {
  if (!container || !path || container.length <= 0 || path.length <= 0) {
    return null;
  }

  return `${BLOB_STORAGE_URL}/${container}/${path}`;
}

export async function uploadFile(file, containerType) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('containerType', containerType);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  return (await postRequest(UPLOAD_FILE_PATH, formData, config)).data;
}
