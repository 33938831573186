import React, { useState } from 'react';
import { useResourcesContext } from '../resource/ResourceContext';
import * as storageService from '../storage/storageService';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import * as authService from '../auth/authService';
import { useTranslation } from 'react-i18next';

export default function Profile() {
  const { t } = useTranslation();
  const { loggedInUserInfo } = useResourcesContext();
  const profilePhotoPath = loggedInUserInfo ? storageService.buildPath(storageService.containers.userPhoto, loggedInUserInfo.photoPath) : null;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleLinkClick(relativePath) {
    handleClose();
    window.location.href = `${process.env.REACT_APP_FIELDPIE_OLD_URL}${relativePath}`;
  }

  async function logout() {
    handleClose();
    await authService.logout();
    handleLinkClick('/logout/');
  }

  if (!loggedInUserInfo || !loggedInUserInfo.id) {
    return <Box></Box>;
  }

  return (
    <Box sx={{ mr: 2, color: '#ffffffd6', fontSize: 14, ml: -2, fontWeight: 400, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {profilePhotoPath && (
        <img
          alt="profile"
          src={profilePhotoPath}
          style={{ maxWidth: '40px', borderRadius: '50%', border: '5px solid rgba(236, 239, 241, 0.1)', marginRight: '10px' }}
        />
      )}
      {loggedInUserInfo.name}
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <SvgIcon sx={{ color: '#ffffffd6', fontSize: 20, maxWidth: 1 }} component={ArrowDropDownIcon} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Users) && (
          <div>
            <MenuItem onClick={() => handleLinkClick(`/user/view/${loggedInUserInfo.id}`)} sx={{ fontWeight: '500', fontSize: '14px', pr: '100px' }}>
              {t('profile')}
            </MenuItem>
            <Divider />
          </div>
        )}

        {authService.hasAccessRight(loggedInUserInfo, authService.accessRights.Subscription) &&
          authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Subscription) && (
            <div>
              <MenuItem onClick={() => handleLinkClick('/subscription/')} sx={{ fontWeight: '500', fontSize: '14px', pr: '100px' }}>
                {t('subscription')}
              </MenuItem>
              <Divider />
            </div>
          )}

        {authService.hasAccessRight(loggedInUserInfo, authService.accessRights.CustomActivity) &&
          authService.hasModuleViewRight(loggedInUserInfo, authService.modules.CustomForms) && (
            <div>
              <MenuItem onClick={() => handleLinkClick('/customforms/')} sx={{ fontWeight: '500', fontSize: '14px', pr: '100px' }}>
                {t('customize_forms')}
              </MenuItem>
              <Divider />
            </div>
          )}

        {authService.hasModuleViewRight(loggedInUserInfo, authService.modules.Settings) && (
          <div>
            <MenuItem onClick={() => handleLinkClick('/settings/')} sx={{ fontWeight: '500', fontSize: '14px', pr: '100px' }}>
              {t('settings')}
            </MenuItem>
            <Divider />
          </div>
        )}

        <MenuItem onClick={() => logout()} sx={{ fontWeight: '500', fontSize: '14px', pr: '100px' }}>
          {t('logout')}
        </MenuItem>
      </Menu>
    </Box>
  );
}
