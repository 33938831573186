import { createTheme } from '@mui/material/styles';
import { enUS, trTR } from '@mui/x-data-grid';
import i18n from 'i18next';

export const MOBILE_WIDTH = 800;

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#009587'
      }
    },
    typography: {
      fontFamily: ['RobotoDraft', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
      fontSize: 13
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: ({ ownerState }) => ({
            backgroundColor: '#009587',
            borderRadius: 2,
            padding: 8,
            fontSize: 12
          }),
          arrow: ({ ownerState }) => ({
            color: '#009587'
          })
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: '#00000099'
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            color: '#455a64',
            '&.Mui-checked': {
              color: '#4caf50'
            }
          })
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            color: '#455a64'
          }),
          columnHeader: ({ ownerState }) => ({
            backgroundColor: '#455a64',
            color: '#fff',
            fontFamily: ['RobotoDraft', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
            fontSize: 13
          }),
          columnHeaderTitle: ({ ownerState }) => ({
            fontSize: 12,
            fontWeight: 800
          }),
          sortIcon: ({ ownerState }) => ({
            color: '#fff'
          })
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            color: ownerState.shrink ? '#009587' : '#b1b8bb'
          })
        }
      }
    }
  },
  i18n.language === 'en' ? enUS : trTR
);

export default theme;
